import HttpRequest from 'app/common/helpers/HttpRequest'
import {HttpRequestType} from 'app/common/helpers/HttpRequest/types'
import ServiceConfig from 'app/common/helpers/ServiceConfig'
import {AxiosResponse} from 'axios'

export const search = (
  params: RewardsTypes.RewardSearchParam
): Promise<AxiosResponse<ResponseTypes.PagedResponse<RewardsTypes.RewardResponseBL[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.searchRewards,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

export const searchDetail = (
  params: RewardsTypes.VoucherDetailSearch
): Promise<AxiosResponse<ResponseTypes.ResponseData<RewardsTypes.RewardResponseBL>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.searchRewardDetail,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}
export const searchPriceDetail = (
  params: RewardsTypes.RewardPriceSearchParam
): Promise<AxiosResponse<ResponseTypes.PagedResponse<RewardsTypes.RewardPriceHistoryState[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.saveRewardPriceHistory,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

export const issueReward = (data: RewardsTypes.IssueRewardBL): Promise<AxiosResponse<ResponseTypes.ResponseData< RewardsTypes.RewardBasicInfo[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.issueReward,
    data,
    withCredentials: false,
    method: 'POST',
  }
  return HttpRequest(requestOptions)
}

export const getActiveOffers = (
  params: RewardsTypes.RewardGetActiveOffersParam
): Promise<AxiosResponse<ResponseTypes.PagedResponse<RewardsTypes.RewardResponseBL[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.getActiveoffers,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

export const saveRewards = (
  data: RewardsTypes.RewardResponseBL,
  params: RewardsTypes.RewardDetails
): Promise<AxiosResponse<ResponseTypes.PagedResponse<RewardsTypes.RewardResponseBL>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.saveRewards,
    data,
    params,
    withCredentials: false,
    method: params.voucherTypeCode ? 'PUT' : 'POST',
  }
  return HttpRequest(requestOptions)
}
export const saveRewardPriceHistory = (
  data: RewardsTypes.RewardPriceHistoryState,
  params:RewardsTypes.RewardPriceHistoryParam
): Promise<AxiosResponse<ResponseTypes.PagedResponse<RewardsTypes.RewardPriceHistoryState>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.saveRewardPriceHistory,
    data,
    params,
    withCredentials: false,
    method: params.voucherPriceHistoryId ? 'PUT' : 'POST',
  }
  return HttpRequest(requestOptions)
}

export const deleteReward = (
  params: RewardsTypes.RewardSearchParam
): Promise<AxiosResponse<ResponseTypes.PagedResponse<boolean>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.removeReward,
    params,
    withCredentials: false,
    method: 'DELETE',
  }
  return HttpRequest(requestOptions)
}
export const deletePriceHIstory = (
  params: RewardsTypes.RewardPriceHistoryDeleteParam
): Promise<AxiosResponse<ResponseTypes.PagedResponse<boolean>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.removePriceHistory,
    params,
    withCredentials: false,
    method: 'DELETE',
  }
  return HttpRequest(requestOptions)
}

export const deleteQunatity = (
  params: RewardsTypes.RewardDeleteQuantityParams
): Promise<AxiosResponse<ResponseTypes.PagedResponse<boolean>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.removeVoucherToken,
    params,
    withCredentials: false,
    method: 'DELETE',
  }
  return HttpRequest(requestOptions)
}

export const generateVoucher = (
  data: RewardsTypes.GenerateVoucherPayload,
): Promise<AxiosResponse<ResponseTypes.ResponseData<RewardsTypes.GenerateVoucherResponse>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.generateVoucher,
    data,
    withCredentials: false,
    method: 'POST',
  }
  return HttpRequest(requestOptions)
}
export const deleteImage = (
  params: RewardsTypes.RewardDeleteParam
): Promise<AxiosResponse<ResponseTypes.ResponseData<boolean>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.deleteVoucherImage,
    params,
    withCredentials: false,
    method: 'POST',
  }
  return HttpRequest(requestOptions)
}

export const searchRewards = (
  params: RewardsTypes.RewardInfoSearchParam
): Promise<AxiosResponse<ResponseTypes.PagedResponse<RewardsTypes.RewardResponseBL[]>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.searchRewardInfo,
    params,
    withCredentials: false,
  }
  return HttpRequest(requestOptions)
}

export const saveVoucherImage = (
  acceptedFiles: File,
  params:RewardsTypes.RewardImageParam
): Promise<AxiosResponse<ResponseTypes.PagedResponse<string>>> => {
  // Create a FormData object
  const formData = new FormData();
  // Assuming you're only allowing a single file upload
  formData.append('voucherImageData', acceptedFiles);


  const requestOptions: HttpRequestType = {
    url: ServiceConfig.saveVoucherImage,
    withCredentials: false,
    data: formData,
    params,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  return HttpRequest(requestOptions)
}

export const resendMail = (
  params: RewardsTypes.VoucherResendMailParams
): Promise<AxiosResponse<ResponseTypes.ResponseData<boolean>>> => {
  const requestOptions: HttpRequestType = {
    url: ServiceConfig.resendVoucherMail,
    params,
    withCredentials: false,
    method: 'POST',
  }
  return HttpRequest(requestOptions)
}


const RewardService = {
  search,
  saveRewards,
  getActiveOffers,
  issueReward,
  deleteReward,
  generateVoucher,
  searchRewards,
  saveVoucherImage,
  deleteImage,
  searchDetail,
  saveRewardPriceHistory,
  searchPriceDetail,
  resendMail,
  deletePriceHIstory,
  deleteQunatity
}

export default RewardService
