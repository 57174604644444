
import {zipcodes} from 'app/common/helpers/zipcodes'
import {FormTypes} from 'lib/components/Form/Form'
import {uniq} from 'lodash'
import {formatPhoneNumber, isValidPhoneNumber} from 'react-phone-number-input'
import {lStorage} from './BrowserStorage'
import Appconfig from 'app/common/helpers/AppConfig'
import { jwtDecode } from 'jwt-decode'
// import {  } from 'app/common/helpers/UserFunctions'

export const numberWithCommas = (x: number) => {
  const parts = x.toString().split('.')
  parts[0] = parts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export const emailRegex = /^(?!.*\.\.)(?!.*\.$)(?!^\.)[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z]{2,})+$/


export const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+=]).{8,}$/

export const phoneNumberRegex = /^\+\d{1,4}\s\(\d{3}\)\s\d{3}-\d{4}$/

export const getStateNameByCode = (code: string) => {
  return states.find((item) => item.value === code)?.label || ''
}

export const validatePhone = (phone?: string): boolean => {
  if (!phone) {
    return false
  }
  const isValid = isValidPhoneNumber(phone)
  return isValid
}

export const normalizePhoneNo = (phone: string) => {
  if (!phone) {
    return ''
  }
  //normalize string and remove all unnecessary characters
  const formattedPhone = formatPhoneNumber(phone)
  if (formattedPhone) {
    return formattedPhone
  }
  return phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
}

export const parseUserDetailsFromJwt = (jwt: string) => {
  const decoded: UserManagementTypes.JwtUserDetailsType = jwtDecode(jwt)
  return decoded
}

export const setRedirectPath = (pathName: string) => {
  lStorage._set(Appconfig.REDIRECT_PATH, {pathName})
}
interface RedirePathInterface {
  pathName?: string
}
export const getRedirectPath = (): RedirePathInterface => {
  return lStorage._get(Appconfig.REDIRECT_PATH)
}

export const removeRedirectPath = () => {
  lStorage._remove(Appconfig.REDIRECT_PATH)
}

export const states = [
  {
    label: 'Alabama',
    value: 'AL',
  },
  {
    label: 'Alaska',
    value: 'AK',
  },
  {
    label: 'Arizona',
    value: 'AZ',
  },
  {
    label: 'Arkansas',
    value: 'AR',
  },
  {
    label: 'California',
    value: 'CA',
  },
  {
    label: 'Colorado',
    value: 'CO',
  },
  {
    label: 'Connecticut',
    value: 'CT',
  },
  {
    label: 'Delaware',
    value: 'DE',
  },
  {
    label: 'Florida',
    value: 'FL',
  },
  {
    label: 'Georgia',
    value: 'GA',
  },
  {
    label: 'Hawaii',
    value: 'HI',
  },
  {
    label: 'Idaho',
    value: 'ID',
  },
  {
    label: 'Illinois',
    value: 'IL',
  },
  {
    label: 'Indiana',
    value: 'IN',
  },
  {
    label: 'Iowa',
    value: 'IA',
  },
  {
    label: 'Kansas',
    value: 'KS',
  },
  {
    label: 'Kentucky',
    value: 'KY',
  },
  {
    label: 'Louisiana',
    value: 'LA',
  },
  {
    label: 'Maine',
    value: 'ME',
  },
  {
    label: 'Maryland',
    value: 'MD',
  },
  {
    label: 'Massachusetts',
    value: 'MA',
  },
  {
    label: 'Michigan',
    value: 'MI',
  },
  {
    label: 'Minnesota',
    value: 'MN',
  },
  {
    label: 'Mississippi',
    value: 'MS',
  },
  {
    label: 'Missouri',
    value: 'MO',
  },
  {
    label: 'Montana',
    value: 'MT',
  },
  {
    label: 'Nebraska',
    value: 'NE',
  },
  {
    label: 'Nevada',
    value: 'NV',
  },
  {
    label: 'New Hampshire',
    value: 'NH',
  },
  {
    label: 'New Jersey',
    value: 'NJ',
  },
  {
    label: 'New Mexico',
    value: 'NM',
  },
  {
    label: 'New York',
    value: 'NY',
  },
  {
    label: 'North Carolina',
    value: 'NC',
  },
  {
    label: 'North Dakota',
    value: 'ND',
  },
  {
    label: 'Ohio',
    value: 'OH',
  },
  {
    label: 'Oklahoma',
    value: 'OK',
  },
  {
    label: 'Oregon',
    value: 'OR',
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
  },
  {
    label: 'Puerto Rico',
    value: 'PR',
  },
  {
    label: 'Rhode Island',
    value: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'SC',
  },
  {
    label: 'South Dakota',
    value: 'SD',
  },
  {
    label: 'Tennessee',
    value: 'TN',
  },
  {
    label: 'Texas',
    value: 'TX',
  },
  {
    label: 'Utah',
    value: 'UT',
  },
  {
    label: 'Vermont',
    value: 'VT',
  },
  {
    label: 'Virginia',
    value: 'VA',
  },
  {
    label: 'Virgin Islands',
    value: 'VI',
  },
  {
    label: 'Washington',
    value: 'WA',
  },
  {
    label: 'Washington DC',
    value: 'DC',
  },
  {
    label: 'West Virginia',
    value: 'WV',
  },
  {
    label: 'Wisconsin',
    value: 'WI',
  },

  {
    label: 'Wyoming',
    value: 'WY',
  },
]

export const getZipcodes = (): FormTypes.Options[] => {
  return uniq(zipcodes).map((item) => ({
    label: item,
    value: item,
  }))
}

export const getFormattedUserRoles = (data: UserManagementTypes.AssignedComponentType[] = []): string[] => {
  const roleComponentList = data.map((item) => item.componentCode)
  // const updatedCompList = roleComponentList.filter(item => ![''].includes(item)).map(func => func)
  return roleComponentList
}
