import {useState, FormEvent, useImperativeHandle, forwardRef} from 'react'
import DataTable from 'lib/components/DataTable'
import Form from 'lib/components/Form'
import {FormTypes} from 'lib/components/Form/Form'
import {FilterItem} from 'app/common/components/Styled/common.styled'

interface RewardsFilterProps {
  handleFilter(formState: RewardsTypes.RewardsFilterState): void
  isFetching: boolean
  categoryDataOptions: FormTypes.Options[]
  isVouchergroupFilterAllowed: boolean
}

type KeyTypes = keyof RewardsTypes.RewardsFilterState

export interface ResetRewardFormRefType {
  handleReset(): void
  getFormState(): RewardsTypes.RewardsFilterState
}

let rewardStatusOptions = [
  {label: 'INPROGRESS', value: 'INPROGRESS'},
  {label: 'PENDING', value: 'PENDING'},
  {label: 'APPROVED', value: 'APPROVED'},
  {label: 'LIVE', value: 'LIVE'},
  {label: 'ENDED', value: 'ENDED'},
  {label: 'REJECTED', value: 'REJECTED'},
]

let rewardTypeOptions = [
  {label: 'Voucher', value: 'Voucher'},
  {label: 'Product', value: 'Product'},
  {label: 'Sweepstake', value: 'Sweepstake'},
  {label: 'Surprise & Delight', value:"DIRECTREWARD"}
]
let rewardGroupOptions = [
  {label: 'Parent', value: 1},
  {label: 'Child', value: 2},
  {label: 'Independent', value: 3},
]
const RewardsFilter = forwardRef<ResetRewardFormRefType, RewardsFilterProps>(
  ({isFetching, handleFilter, categoryDataOptions, isVouchergroupFilterAllowed}, ref) => {
    const initialState: RewardsTypes.RewardsFilterState = {
      voucherTypeName: '',
      voucherTypeCode: '',
      voucherStatus: '',
      voucherTypeSubCategory: '',
      CategoryList: [],
      voucherTypeGroup: undefined,
    }
    const [state, setInitialState] = useState<RewardsTypes.RewardsFilterState>(initialState)

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      handleFilter(state)
    }

    const handleReset = () => {
      setInitialState(initialState)
    }

    const getFormState = (): RewardsTypes.RewardsFilterState => {
      return {
        voucherTypeName: state.voucherTypeName,
        voucherTypeCode: state.voucherTypeCode,
        voucherStatus: state.voucherStatus,
        voucherTypeSubCategory: state.voucherTypeSubCategory,
        CategoryList: state.CategoryList,
        voucherTypeGroup: state.voucherTypeGroup,
      }
    }

    const handleOnChange = (value: string & number & string[], key: KeyTypes): void => {
      const prevState = {...state}
      prevState[key] = value
      setInitialState(prevState)
    }

    useImperativeHandle(ref, () => ({handleReset, getFormState}))

    return (
      <DataTable.Filter isFetching={isFetching} handleSubmit={handleSubmit} extendedFilters>
        <Form.Input
          value={state.voucherTypeName}
          name="voucherTypeName"
          id="voucherTypeName"
          placeholder="Search by Reward Name"
          onChange={handleOnChange}
          isDark
        />
        <Form.Input
          value={state.voucherTypeCode}
          name="voucherTypeCode"
          id="voucherTypeCode"
          placeholder="Search by Reward ID"
          onChange={handleOnChange}
          isDark
        />
        <FilterItem width="225px">
          <Form.Select
            value={state.voucherStatus}
            options={rewardStatusOptions}
            placeholder="Search by Status"
            id="voucherStatus"
            onChange={handleOnChange}
            noMargin
            isDark
          />
        </FilterItem>
        <FilterItem width="250px">
          <Form.Select
            value={state.voucherTypeSubCategory}
            options={rewardTypeOptions}
            placeholder="Search by Reward Type"
            id="voucherTypeSubCategory"
            onChange={handleOnChange}
            noMargin
            isDark
          />
        </FilterItem>
        <FilterItem width="50%">
          <Form.Select
            value={state.CategoryList}
            options={categoryDataOptions}
            placeholder="Select Categories"
            id="CategoryList"
            onChange={handleOnChange}
            noMargin
            isDark
            mode="multiple"
          />
        </FilterItem>
        {!isVouchergroupFilterAllowed && (
          <FilterItem width="250px">
            <Form.Select
              value={state.voucherTypeGroup}
              options={rewardGroupOptions}
              placeholder="Search by Group"
              id="voucherTypeGroup"
              onChange={handleOnChange}
              noMargin
              isDark
            />
          </FilterItem>
        )}
      </DataTable.Filter>
    )
  }
)

export default RewardsFilter
