import styled from 'styled-components'
import Colors from 'styles/Colors'

export const SecondaryHeader = styled.div`
  padding: 18px 18px 18px 0;
  border-bottom: 1px solid ${Colors.BORDER2};
`

export const NewRewardFormWrapper = styled.div`
  padding-top: 30px;
`

export const RewardsFormBodyContainer = styled.div`
  border: 1px solid ${Colors.BORDER1};
  background-color: ${Colors.WHITE};
  padding: 34px;
`

export const RewardDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  border-bottom: 1px solid ${Colors.BORDER2};
  padding-bottom: 30px;
`
export const RewardSectionHeader = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${Colors.BORDER2};
`

export const RewardTriggerSectionWrapper = styled.div`
  padding-top: 34px;
`
export const RewardDatePickerWrapper=styled.div`
  display: flex;
  align-items: center;
`

export const NewRewardFooter = styled.div`
  padding-bottom: 15px;
  padding-top: 15px;
  display: flex;
  justify-content: flex-end;
  .lb-btn {
    min-width: 150px;
    margin-left: 8px;
  }
`

export const RewardDetailsRow = styled.div`
  margin-bottom: 24px;
  display: flex;
  column-gap: 32px;
`
export const RewardDetailsPriceRow = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const ReviewDetailsItem = styled.div`
  margin-bottom: 32px;
`
export const AssignedPromotionLabel=styled.p`
  color: ${Colors.PRIMARY};
`

export const StyledReviewLabel = styled.div`
  font-size: ${(props) => props.theme.typography.fontSize}px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  color: ${Colors.TEXT_COLOR_PRIMARY};
  line-height: 1.5;
  margin-bottom: 8px;
`

export const StyledReviewValue = styled.div`
  font-size: ${(props) => props.theme.typography.fontSize}px;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
  color: ${Colors.TEXT_COLOR_PRIMARY};
  max-width:1000px;
  word-wrap: break-word;
`

export const RewardTriggerDetailsWrapper = styled.div`
  padding: 32px 0;
`

export const RewardImageListContainer = styled.div`
  display: flex;
`
export const RewardImageList = styled.div`
  padding: 0 5px;
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
`
export const RewardImageListBox = styled.div`
  display: flex;
`
export const RewardImageBox = styled.div`
  width: 134px;
  height: 116px;
  border-radius: 2px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 5px;
  border: 1px solid #dcdcdc;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`

export const InputNumberWrapper = styled.div`
  display: flex;
`

export const InputNumberUnit = styled.div`
  /* background-color: ${Colors.GREY7}; */
  font-size: ${(props) => props.theme.typography.h4.fontSize};
  font-weight: ${(props) => props.theme.typography.h4.fontWeight};
  line-height: ${(props) => props.theme.typography.h4.lineHeight};
  letter-spacing: ${(props) => props.theme.typography.h4.letterSpacing};
  color: ${Colors.GREY3};
  padding: 8px;
  display: flex;
  align-items: center;
  margin-top: 16px;
`

export const TierSelectionWrapper = styled.div`
  margin-top: 32px;
`

export const TriggerQuestionWrapper = styled.div`
  margin-bottom: 16px;
`

export const TriggerDetailsWrapper = styled.div`
  border-bottom: 1px solid ${Colors.BORDER2};
  padding-bottom: 32px;
`

export const RewardRestrictionWrapper = styled.div`
  padding-bottom: 32px;
`

export const StyledSectionLabel = styled.div`
  margin-bottom: 16px;
`

export const StyledAddQtyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  margin-bottom: 32px;
  width: 100%;
`

export const ModalActionStyled = styled.div`
  display: flex;
  column-gap: 16px;
`
export const AwardSalePriceWrapper = styled.div`
  background-color: rgba(243, 243, 243, 0.5);
  padding: 24px 24px 12px 24px;
  width: 60%;
  flex-direction: column;
  align-items: center;
  margin-bottom:24px;
`

export const PriceHistoryTitle=styled.div`
display:flex;
justify-content: space-between;
flex-direction: row;
align-items: center;
`

export const PriceHistoryRowContainer=styled.div`
margin-top: 12px;
`