const Appconfig = {
  AUTH_TOKEN_LOCAL_STORAGE_KEY: 'loginToken',
  AUTH_TOKEN: '',
  REDIRECT_PATH: 'redirectTo',
  SAVED_USERNAME_KEY: 'loggedInUser',
  SAVED_PASSWORD_KEY: 'loggedInUserPassword',
  DATE_FORMAT_MONTH_NAME: 'MMMM D, YYYY',
  DATE_FORMAT: 'MMM-DD-YYYY',
  DATE_FORMAT_SLASH: 'MM/DD/YYYY',
  DATE_TIME_FORMAT: 'MM-DD-YYYY hh:mm A',
  DATE_TIME_SERVER: 'YYYY-MM-DDTHH:mm',
  DATE_TIME_BIRTHDAY_DATE: 'YYYY-MM-DDTHH:mm:ss.SSS',
  LOGIN_URL: 'https://loyalty-engagement.com/login',
  DEFAULT_SERVER_ERROR: 'A temporary issue prevented us from processing your request. Please try again later.',
  SOURCE: 'ADMIN',
  REFERRALURL: 'qa-internal-admin-client01.ebbo.com',
  MEMBER: {
    DEFAULT_POINTS_RANGE: [100, 1000, 2000, 3000],
    DEFAULT_POINT: '250',
  },
  DEFAULT_PAGINATION: {
    PAGINATION_OPTIONS: [25, 50, 100],
    DEFAULT_PAGE_SIZE: 25,
    DEFAULT_SORTING_ORDER: 'desc',
    DEFAULT_PAGE_NUMBER: 1,
  },
  NEW_DEFAULT_PAGIANTION: {
    PAGINATION_OPTIONS: [10, 20, 30],
    DEFAULT_PAGE_SIZE: 10,
    DEFAULT_SORTING_ORDER: 'desc',
    DEFAULT_PAGE_NUMBER: 1,
  },
  promotions: {
    defaultValus: {
      promotionType: 'Transaction',
      rowStatus: 1,
      promotionStatusCode: 'INCOMPLETE',
    },
    statusCodes: {
      approved: 'APPROVED',
      draft: 'DRAFT',
      inComplete: 'INCOMPLETE',
      pending: 'PENDING',
      rejected: 'REJECTED',
    },
  },
  rewards: {
    defaultValus: {
      voucherTypeCategory: 'BENEFIT',
      rowStatus: 1,
      voucherStatusCode: 'INPROGRESS',
    },
    statusCodes: {
      inProgress: 'INPROGRESS',
      draft: 'DRAFT',
      pending: 'PENDING',
      approved: 'APPROVED',
      notApproved: 'NOT APPROVED',
      live: 'LIVE',
      ended: 'ENDED',
      rejected:'REJECTED'
    },
  },
  segment: {
    defaultValues: {
      segmentStatus: 'INPROGRESS',
    },
  },

  tokenStaus: {
    INVALID: 'INVALID',
    VALID: 'VALID',
    USED: 'USED',
    EXPRIED: 'EXPRIED',
  },
  defaultFileType: 'CSV',
}

export default Appconfig
