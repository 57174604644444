import {InputNumber as AntInputNumber} from 'antd'
import {FormTypes} from '../Form'
import FormLabel from '../FormLabel'
import {InputWrapper, TextInputRoot, TextInputWrapper} from '../Input/Input.Styled'
import {valueType} from 'antd/es/statistic/utils'

const InputNumber = (props: FormTypes.InputNumber) => {
  const handleInputChange = (value: valueType | null) => {
    props.onChange && props.onChange(Number(value), props.id)
  }

  const renderInput = () => {
    if (props.onChange) {
      return (
        <InputWrapper $isDark={props.isDark}>
          <AntInputNumber
            aria-labelledby={`${props.id}-label`}
            onChange={handleInputChange}
            disabled={props.disabled}
            id={props.id}
            value={props.value}
            name={props.id}
            placeholder={props.placeholder}
            status={props.status}
            min={props.min}
            max={props.max}
            width={props.width}
            precision={props.precision}
            addonAfter={props.addOnAfter}
            formatter={(value) => value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || ''}
            parser={(value) => value?.replace(/,/g, '') || 0}
          />
        </InputWrapper>
      )
    }
    return (
      <InputWrapper $isDark={props.isDark}>
        <AntInputNumber
          type="number"
          aria-labelledby={`${props.id}-label`}
          disabled={props.disabled}
          value={props.value}
          id={props.id}
          name={props.id}
          placeholder={props.placeholder}
          status={props.status}
          min={props.min}
          max={props.max}
          width={props.width}
          precision={props.precision}
          formatter={(value) => value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || ''}
          parser={(value) => value?.replace(/,/g, '') || 0}
        />
      </InputWrapper>
    )
  }
  return (
    <TextInputWrapper>
      {props.label && props.id && <FormLabel id={props.id} label={props.label} isDark={props.isDark} />}
      <TextInputRoot>{renderInput()}</TextInputRoot>
      {props.children}
    </TextInputWrapper>
  )
}

InputNumber.Label = FormLabel

export default InputNumber
