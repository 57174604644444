import {useState, useEffect} from 'react'
import {Button, Col, Row} from 'antd'
import Appconfig from 'app/common/helpers/AppConfig'
import Typography from 'lib/components/Typography'
import {getPromotionRequestObj, isApprovedWithPastDate, memberCountData, updateTierDataForReview} from '../../utils'
import IncentiveDetails from './IncentiveDetails'
import {ReviewRow} from './Review.styled'
import TierDetails from './TierDetails'
import TriggerDetails from './TriggerDetails'
import MembershipService from 'app/services/MemberService'
import {useParams} from 'react-router'
import {CreatePromotionParam} from '../..'
import {utcDate, utcWithTimeZone} from 'utils/convertDateToString'
// import UserManagementService from 'app/services/UserManagementService'
import SegmentDetails from './SegmentDetails'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {PROMOTION_EDIT} from 'app/common/helpers/UserFunctions'
import PointService from 'app/services/PointService'
import {FormTypes} from 'lib/components/Form/Form'
// import OptDetails from './OptDetails'

interface ReviewProps {
  onEdit(stepId: number): void
  partnerCode: string
  isViewOnly?: boolean
}

const Review: React.FC<ReviewProps> = ({onEdit, isViewOnly, partnerCode}) => {
  const {roles} = useAuth()
  const showEdit = roles.includes(PROMOTION_EDIT)
  const promotionRequestObj = getPromotionRequestObj
  const {startDateTime, endDateTime, promotionRuleData, promotionStatusCode} = promotionRequestObj
  const [tierData, setTierData] = useState<PromotionsTypes.PromotionMemberCountDataType | null>(memberCountData)
  const [pointTypeOptions, setPointTypeOptions] = useState<FormTypes.Options[]>([])
  const {promotionId} = useParams<CreatePromotionParam>()
  const actualPromotionId = atob(promotionId || '')
  const promotionType = promotionRequestObj.promotionType.toUpperCase()
  const incentiveData = promotionRuleData.incentiveRuleData?.incentiveData
  const whoData = promotionRuleData.whoData
  const limitData = promotionRuleData.triggerRuleData?.limitData
  const isDisabelEdit = isApprovedWithPastDate(getPromotionRequestObj) || promotionStatusCode.toUpperCase() === 'PENDING'
  let isWhenEditDisabel = false
  if (isApprovedWithPastDate(getPromotionRequestObj)) {
    if (getPromotionRequestObj.endDateTime !== null) {
      const endDateTime = new Date(getPromotionRequestObj.endDateTime)
      const currentDateTime = new Date(new Date().toISOString())

      isWhenEditDisabel = endDateTime < currentDateTime
      console.log(isWhenEditDisabel)
    }
  }

  useEffect(() => {
    if (actualPromotionId !== 'new') {
      const whoData = promotionRequestObj.promotionRuleData.whoData
      MembershipService.memberCount({partnerCode: promotionRequestObj.partnerCode}).then((res) => {
        const {totalCount, tierCount} = res.data.data
        const tierData = tierCount.map((item) => {
          if (whoData.tierData?.findIndex((tier) => tier.membershipTierKey === item.tierCode) !== -1) {
            return {...item, isChecked: true}
          }
          return {...item, isChecked: false}
        })
        const {isAllMembers} = whoData
        updateTierDataForReview(isAllMembers, totalCount, tierData)
        setTierData({
          isAllMembers,
          totalCount,
          tierData,
        })
      })
    }
    PointService.getPointTypeCodes({pointTypeCode: undefined}).then((res) => {
      const data = res.data.data
      const formattedOptions = data.map((item) => ({
        label: item.pointTypeName,
        value: item.pointTypeCode,
      }))
      setPointTypeOptions(formattedOptions)
    })
  }, [])

  const handleEdit = (stepId: number) => {
    onEdit(stepId)
  }

  const getTriggerData = (promoType: string) => {
    switch (promoType.toUpperCase()) {
      case 'TRANSACTION':
        return promotionRuleData.triggerRuleData?.triggerData
      case 'ACTIVITY':
        return promotionRuleData.triggerRuleData?.activityRuleData
      case 'ENROLLMENT':
        return promotionRuleData.triggerRuleData?.enrollmentRuleData
      case 'EVENT':
        return promotionRuleData.triggerRuleData?.eventRuleData
      case 'MULTICOUNTER':
        return promotionRuleData.triggerRuleData?.multiCounterData
      // Add case for Spend data
      case 'SPEND':
        return promotionRuleData.triggerRuleData?.spendRuleData
      default:
        return
    }
  }

  const triggerData = getTriggerData(promotionType)

  return (
    <>
      {whoData && ((whoData.tierData && whoData.tierData.length > 0) || whoData.isAllMembers) && (
        <TierDetails isViewOnly={isViewOnly} isDisabelEdit={isDisabelEdit} data={tierData} onEdit={handleEdit} showEdit={showEdit} />
      )}
      {whoData && (whoData.promotionSegment || whoData.memberOptIns) && (
        <SegmentDetails isViewOnly={isViewOnly} isDisabelEdit={isDisabelEdit} data={whoData} onEdit={handleEdit} showEdit={showEdit} />
      )}
      {/* {whoData && whoData.memberOptIns && <OptDetails isViewOnly={isViewOnly} isDisabelEdit={isDisabelEdit} onEdit={handleEdit} />} */}
      <ReviewRow>
        <div style={{width: '100%'}}>
          <Row style={{marginBottom: '24px'}}>
            <Typography.Text strong uppercase>
              Duration
            </Typography.Text>
          </Row>
          <Row>
            {startDateTime && (
              <Col span={6}>
                <Typography.Text size="small" uppercase>
                  From
                </Typography.Text>
                <div>{utcDate(utcWithTimeZone(startDateTime), Appconfig.DATE_TIME_FORMAT)}</div>
              </Col>
            )}
            {endDateTime && (
              <Col span={6}>
                <Typography.Text size="small" uppercase>
                  To
                </Typography.Text>
                <div>{utcDate(utcWithTimeZone(endDateTime), Appconfig.DATE_TIME_FORMAT)}</div>
              </Col>
            )}
          </Row>
        </div>
        <div>
          {!isViewOnly && showEdit && promotionStatusCode.toUpperCase() !== 'PENDING' && (
            <Button onClick={() => handleEdit(1)} size="small" type="link">
              Edit
            </Button>
          )}
        </div>
      </ReviewRow>
      {triggerData && (
        <TriggerDetails
          isViewOnly={isViewOnly}
          data={triggerData}
          promotionType={promotionType}
          onEdit={handleEdit}
          isDisabelEdit={isDisabelEdit}
          limitData={limitData}
          showEdit={showEdit}
          pointTypeOptions={pointTypeOptions}
        />
      )}
      {incentiveData && incentiveData[0] && (
        <IncentiveDetails isViewOnly={isViewOnly} data={incentiveData} onEdit={handleEdit} isDisabelEdit={isDisabelEdit} showEdit={showEdit} />
      )}
    </>
  )
}

export default Review
