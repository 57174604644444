import Breadcrumbs from 'lib/components/Breadcrumbs'
import {BreadcrumbsHeaderWrapper} from 'lib/components/Breadcrumbs/Breadcrumbs.styled'
import Button from 'lib/components/Button'
import RewardsHeader from './RewardsHeader'
import NewRewardForm, {RewardRefType} from './NewRewardForm'
import {NewRewardFooter, RewardsFormBodyContainer} from '../Reward.Styled'
import {useNavigate, useParams} from 'react-router-dom'
import {useEffect, useRef, useState} from 'react'
import IconArrowRightLong from 'app/common/icons/IconArrowRightLong'
import Colors from 'styles/Colors'
import {Col, Row} from 'antd'
import Form from 'lib/components/Form'
import RewardService from 'app/services/RewardService'
import {
  getRewardsRequestObj,
  initialRewardsRequestObj,
  rewardRuleDataInitialState,
  rewardStatus,
  updateRewardTiersData,
  updateRewardsRequestObj,
} from './utils'
import LoadingPanel from 'lib/components/LoadingPanel'
import ReviewRewards from './ReviewRewards'
import PartnerService from 'app/services/PartnerService'
import {FormTypes} from 'lib/components/Form/Form'
import MembershipService from 'app/services/MemberService'
import {toast} from 'react-hot-toast'
import {Modal} from 'antd'
import TextArea from 'lib/components/TextArea'
import Typography from 'lib/components/Typography'
import {MarginBottomWrapper, ModalTitle, Seperator, StyledModalContent} from 'app/common/components/Styled/common.styled'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {REWARD_CREATE, REWARD_EDIT} from 'app/common/helpers/UserFunctions'
import Appconfig from 'app/common/helpers/AppConfig'
import TagService from 'app/services/TagService'
import RewardCategoryService from 'app/services/RewardCategoryService'
import {getActiveUsers, getApprovalManagerDetails, updateApprovalManagers} from 'app/pages/Promotions/CreatePromotion/utils'
import LoginService from 'app/services/LoginService'
import {parseUserDetailsFromJwt} from 'utils/common'
import {StepsBodyContainer, StyledModal} from 'app/pages/Promotions/CreatePromotion/Steps/Steps.styled'
import IconSuccess from 'app/common/icons/IconSuccess'
import UserManagementService from 'app/services/UserManagementService'

export type AddNewRewardParam = {
  rewardId: string | 'new' | 'view'
}

interface AddNewRewardProps {
  isViewOnly?: boolean
}

const AddNewRewards: React.FC<AddNewRewardProps> = ({isViewOnly}) => {
  const initialPaginationObj: PaginationType.PaginationDetails = {
    PageCount: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
    PageNumber: 1,
    SortColumn: 'UpdatedOn',
    Strict: false,
    SortDirection: Appconfig.DEFAULT_PAGINATION.DEFAULT_SORTING_ORDER,
  }
  const navigate = useNavigate()
  const {rewardId} = useParams<AddNewRewardParam>()
  const actualRewardId = atob(rewardId || '')
  const token = LoginService.getSessionToken()
  const decoded: any = parseUserDetailsFromJwt(token)
  const rewardFormRef = useRef<RewardRefType>(null)
  const [activeStep, setActiveStep] = useState<number>(rewardId === 'new' ? 0 : 1)
  const [loadingRewards, setLoadingRewards] = useState(false)
  const [open, openConfirmModal] = useState(false)
  const [tiersData, setTiersData] = useState<MemebershipTypes.TierCountType[]>([])
  const [individualReward, setIndividualReward] = useState<RewardsTypes.RewardResponseBL>()
  const [partnerCodeOptions, setPartnerCodeOptions] = useState<FormTypes.Options[]>([])
  const [rewardDataOptions, setRewardsDataOptions] = useState<FormTypes.Options[]>([])
  const [tagsDataOptions, setTagsDataOptions] = useState<FormTypes.Options[]>([])
  const [categoryDataOptions, setCategoryDataOptions] = useState<FormTypes.Options[]>([])
  // const [currentqty, setCurrentqyt] = useState(0)
  const allowBack = activeStep === 1
  const [loading, setLoading] = useState(false)
  const [showFirst, setShowFirst] = useState(true)
  const {roles} = useAuth()
  const showRewardEdit = roles.includes(REWARD_EDIT)
  const showRewardCreate = roles.includes(REWARD_CREATE)
  const [openApproval, setOpenApproval] = useState(false)
  const [managerOptions, setManagerOptions] = useState<FormTypes.Options[]>([])
  const [approvalManager, setApprovalManager] = useState<string>('')
  const [sentForApproval, setSentForApproval] = useState(false)
  const [approved, setApproved] = useState(false)
  const [rejected, setRejected] = useState(false)
  const [comments, setComments] = useState<string>('')
  const [confirmRejection, setConfirmRejection] = useState(false)
  const [isSaving, setSaveLoading] = useState(false)
  let isManager = false
  if (getRewardsRequestObj.approvalManager && getRewardsRequestObj.approvalManager.length) {
    isManager = decoded.email === getRewardsRequestObj.approvalManager[0].userName
  }
  const {user} = useAuth()
  const loggedInUserId = decoded.email

  const updateRewardsReqPartnerData = (data: PartnerTypes.PartnerBL[]) => {
    const partnerCodeFromRequest = getRewardsRequestObj.partnerCode
    const defaultCode = data.find((partnerOption) => partnerOption.isDefaultPartner)
    if (partnerCodeFromRequest) {
      const hasPartnerCode = data.find((partnerOption) => partnerOption.partnerCode === partnerCodeFromRequest)
      if (!hasPartnerCode) {
        getRewardsRequestObj.partnerCode = defaultCode ? defaultCode.partnerCode : ''
      }
    } else {
      getRewardsRequestObj.partnerCode = defaultCode ? defaultCode.partnerCode : ''
    }
    getMemberCount(getRewardsRequestObj.partnerCode)
  }

  const getPartnerCodes = () => {
    PartnerService.getPartners().then((res) => {
      const data = res.data.data
      const partnerOptions = data.map((item) => ({value: item.partnerCode, label: item.partnerName}))
      updateRewardsReqPartnerData(data)
      setPartnerCodeOptions(partnerOptions)
    })
    // getTags({...initialPaginationObj, isAll: true})
  }

  const getMemberCount = (partnerCode: string) => {
    MembershipService.memberCount({partnerCode}).then((res) => {
      const {tierCount} = res.data.data
      updateRewardTiersData(tierCount)
      setTiersData(tierCount)
    })
  }
  // const getCurrentInventory = async (voucherTypeCode: string) => {
  //   const reqParam: RewardsTypes.RewardInfoSearchParam = {
  //     voucherStatus: 'AVAILABLE',
  //     voucherTypeCode: voucherTypeCode,
  //     Strict: true,
  //   }
  //   RewardService.searchRewards(reqParam)
  //     .then((res) => {
  //       const data = res.data
  //       setCurrentqyt(data.pageInfo.count)
  //     })
  //     .finally(() => {})
  // }

  const handleCancelApproval = () => {
    setOpenApproval(false)
    setComments('')
    setSaveLoading(false)
    setApprovalManager(getActiveUsers[0].userName)
  }
  const handleSuccessApproval = () => {
    setOpenApproval(false)
    setSentForApproval(false)
    toast.success('Data Saved Sucessfully !')
    navigate(`/rewards`)
  }

  const confirmRejectApproval = () => {
    setConfirmRejection(true)
    setOpenApproval(true)
  }
  const handleApproved = () => {
    setOpenApproval(false)
    setApproved(false)
    navigate(`/rewards`)
  }
  const handleRejectionSuccess = () => {
    setOpenApproval(false)
    setRejected(false)
    setConfirmRejection(false)
    toast.success('Data Saved Sucessfully !')
    navigate(`/rewards`)
  }
  const handleCancelRejection = () => {
    setConfirmRejection(false)
    setOpenApproval(false)
    setComments('')
  }
  const handleClosePopup = () => {
    if (sentForApproval) {
      handleSuccessApproval()
    } else if (confirmRejection) {
      handleCancelRejection()
    } else if (rejected) {
      handleRejectionSuccess()
    } else if (approved) {
      handleApproved()
    } else {
      handleCancelApproval()
    }
  }

  const getModalWidth = () => {
    if (confirmRejection) {
      return '650px'
    }
    if (!sentForApproval && !confirmRejection && !rejected && !approved && openApproval) {
      return '650px'
    }
    return undefined
  }

  const getApprovedRewards = (params: RewardsTypes.RewardSearchParam): void => {
    const reqParam: RewardsTypes.RewardSearchParam = {
      ...params,
      isLimitedInventory: true,
      voucherTypeGroup: 2,
      voucherStatus: 'APPROVED',
      isAll: true,
      enabled: true,
    }
    RewardService.search(reqParam)
      .then((res) => {
        const data = res.data
        const updatedData = data.data.map((item) => ({
          label: `${item.voucherTypeName}-${item.voucherTypeCode}`,
          value: item.voucherTypeCode,
        }))
        setRewardsDataOptions([...updatedData])
      })
      .finally(() => {})
  }

  const getTags = (params: TagTypes.TagSearchParam): void => {
    TagService.getTags(params)
      .then((res) => {
        const data = res.data.data
        setTagsDataOptions(
          data.map((item) => ({
            label: item.voucherTagName,
            value: item.voucherTagCode,
          }))
        )
      })
      .finally(() => {})
  }
  const getCategories = (params: RewardCategoryTypes.RewardCatgSearchParam): void => {
    RewardCategoryService.getRewardCategories(params)
      .then((res) => {
        const data = res.data
        setCategoryDataOptions(
          data.data.map((item) => ({
            label: item.categoryName,
            value: item.categoryCode,
          }))
        )
      })
      .finally(() => {})
  }
  const getUserDetails = (): void => {
    UserManagementService.searchUser({...initialPaginationObj, isAll: true, status: 'Active'})
      .then((res) => {
        const data = res.data.data
        updateApprovalManagers(data)
      })
      .finally(() => {})
  }

  useEffect(() => {
    getPartnerCodes()
    getUserDetails()
    getApprovedRewards(initialPaginationObj)
    getTags({...initialPaginationObj, isAll: true})
    getCategories({...initialPaginationObj, isAll: true})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    getTags({...initialPaginationObj, isAll: true, partnerCode: getRewardsRequestObj.partnerCode})
  }, [getRewardsRequestObj.partnerCode])
  useEffect(() => {
    if (rewardId !== 'new') {
      setLoadingRewards(true)
      setLoadingRewards(false)
      RewardService.searchDetail({voucherTypeCode: actualRewardId})
        .then((res) => {
          const data = res.data.data
          if (data.rewardRuleData === null) {
            data.rewardRuleData = rewardRuleDataInitialState
          }
          setIndividualReward(data)
          updateRewardsRequestObj(data)
        })
        .finally(() => {
          setLoadingRewards(false)
        })
    }
  }, [actualRewardId])

  const handleEditReward = () => {
    setActiveStep(0)
    // getCurrentInventory(actualRewardId)
  }
  const cleanRewardData = (text: string) => {
    return text.replace(/<\/?p>/g, '')
  }

  const showPreview = () => {
    if (rewardFormRef.current?.validate()) {
      setLoading(true)
      if (getRewardsRequestObj.rewardRuleData === null) {
        getRewardsRequestObj.rewardRuleData = rewardRuleDataInitialState
      }
      if (getRewardsRequestObj?.voucherTypeShortDescription != null) {
        getRewardsRequestObj.voucherTypeShortDescription = cleanRewardData(getRewardsRequestObj.voucherTypeShortDescription)
      }
      if (getRewardsRequestObj?.voucherTypeFullDescription != null) {
        getRewardsRequestObj.voucherTypeFullDescription = cleanRewardData(getRewardsRequestObj.voucherTypeFullDescription)
      }
      if (getRewardsRequestObj?.rewardDynamicBody != null) {
        getRewardsRequestObj.rewardDynamicBody = cleanRewardData(getRewardsRequestObj.rewardDynamicBody)
      }
      if (getRewardsRequestObj.rewardDynamicFooter != null) {
        getRewardsRequestObj.rewardDynamicFooter = cleanRewardData(getRewardsRequestObj.rewardDynamicFooter)
      }
      RewardService.saveRewards(getRewardsRequestObj, {voucherTypeCode: rewardId !== 'new' ? getRewardsRequestObj.voucherTypeCode : undefined})
        .then((res) => {
          const {data} = res.data
          updateRewardsRequestObj(data)
          const encodedVoucherCode = btoa(data.voucherTypeCode)
          navigate(`/rewards/${encodedVoucherCode}`)
          setActiveStep(1)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const handleNext = () => {
    const updatedManagerOptions = getActiveUsers
      .map((item) => {
        const name = []
        if (item.firstName) {
          name.push(item.firstName)
        }
        if (item.middleName) {
          name.push(item.middleName)
        }
        if (item.lastName) {
          name.push(item.lastName)
        }
        return {
          label: name.join(' '),
          value: item.userName,
        }
      })
      .filter((item) => item.value !== loggedInUserId)
    const loggedUserDetails = getActiveUsers.find((item) => item.userName === loggedInUserId)
    const isManagerSameAsUser = loggedUserDetails?.manager[0]?.userName === loggedInUserId
    setManagerOptions(updatedManagerOptions)
    setApprovalManager(isManagerSameAsUser ? updatedManagerOptions[0].value : loggedUserDetails?.manager[0].userName || '')
    setOpenApproval(true)
  }

  const handleSubmitForApproval = () => {
    if (!approvalManager) {
      toast.error('Please select approval Manager.')
      return
    }
    const updatedReqObj: RewardsTypes.RewardResponseBL = {
      ...getRewardsRequestObj,
      properties: {
        ...getRewardsRequestObj.properties,
        Comment: comments,
        CreatedBy: user?.firstName + ' ' + user?.lastName,
      },
    }
    updateRewardsRequestObj(updatedReqObj)
    const approvalManagerObj = getApprovalManagerDetails(approvalManager)
    if (approvalManagerObj) {
      getRewardsRequestObj.approvalManager = [approvalManagerObj]
    }
    submitForApproval(getRewardsRequestObj, rewardStatus.PENDING)
  }
  const handleChange = (value: string, id: string) => {
    if (id === 'comments') {
      setComments(value)
    } else {
      setApprovalManager(value)
    }
  }

  const handleSubmitForRejection = () => {
    if (!comments) {
      toast.error('Please enter rejection comments')
      return
    }
    getRewardsRequestObj.properties = {
      ...getRewardsRequestObj.properties,
      RejectReason: comments,
    }
    submitForApproval(getRewardsRequestObj, rewardStatus.REJECTED)
  }
  const approvePromotion = () => {
    submitForApproval(getRewardsRequestObj, rewardStatus.APPROVED)
  }

  const submitForApproval = (request: RewardsTypes.RewardResponseBL, status: string) => {
    const {statusCodes} = Appconfig.rewards
    setSaveLoading(true)
    setLoading(true)
    let isApproved = getRewardsRequestObj.voucherStatus === 'APPROVED'
    request.voucherStatus = status
    RewardService.saveRewards(request, {voucherTypeCode: getRewardsRequestObj.voucherTypeCode})
      .then((res) => {
        const {data} = res.data
        updateRewardsRequestObj(data)
        if (isApproved) {
          toast.success('Data Saved Sucessfully !')
          navigate(`/rewards`)
        } else {
          if (getRewardsRequestObj.voucherStatus != null) {
            if (getRewardsRequestObj.voucherStatus.toUpperCase() === statusCodes.pending) {
              setSentForApproval(true)
              setComments('')
            } else if (getRewardsRequestObj.voucherStatus.toUpperCase() === statusCodes.rejected) {
              setConfirmRejection(false)
              setRejected(true)
            } else {
              setApproved(true)
              setOpenApproval(true)
            }
          }
        }

        // toast.success('Data Saved Sucessfully !')
        // navigate(`/rewards`)
      })
      .catch(() => {
        toast.error('Something went wrong!')
      })
      .finally(() => {
        setSaveLoading(false)
        setLoading(false)
      })
  }

  const handleCancelConfirm = () => {
    updateRewardsRequestObj(initialRewardsRequestObj)
    navigate('/rewards')
  }

  const handleOk = () => {
    openConfirmModal(false)
    handleCancelConfirm()
  }

  const handleCancelNewReward = () => {
    if (isViewOnly === true) {
      handleCancelConfirm()
    } else {
      openConfirmModal(true)
    }
  }
  const handleCancelModal = () => {
    openConfirmModal(false)
  }

  const handleTierTypeChange = (id: keyof RewardsTypes.TriggerRulesData, value: boolean) => {
    let updatedTierType = getRewardsRequestObj.rewardRuleData && getRewardsRequestObj.rewardRuleData[id as keyof RewardsTypes.TriggerRulesData]
    if (updatedTierType) {
      updatedTierType = {
        ...updatedTierType,
        triggerData: {
          ...updatedTierType.triggerData,
          isAllTiers: value,
          tierData: value ? null : [],
        },
      }
      if (getRewardsRequestObj.rewardRuleData) {
        const data: RewardsTypes.RewardResponseBL = {
          ...getRewardsRequestObj,
          rewardRuleData: {
            ...getRewardsRequestObj.rewardRuleData,
            [id]: updatedTierType,
          },
        }
        updateRewardsRequestObj(data)
        setIndividualReward(data)
      }
    }
  }

  const onTierSelection = (id: keyof RewardsTypes.TriggerRulesData, tierData: RewardsTypes.RewardTierInfo[]) => {
    let updatedTierType = getRewardsRequestObj.rewardRuleData && getRewardsRequestObj.rewardRuleData[id as keyof RewardsTypes.TriggerRulesData]
    if (updatedTierType) {
      updatedTierType = {
        ...updatedTierType,
        triggerData: {
          ...updatedTierType.triggerData,
          tierData,
        },
      }
      if (getRewardsRequestObj.rewardRuleData) {
        const data: RewardsTypes.RewardResponseBL = {
          ...getRewardsRequestObj,
          rewardRuleData: {
            ...getRewardsRequestObj.rewardRuleData,
            [id]: updatedTierType,
          },
        }
        updateRewardsRequestObj(data)
        setIndividualReward(data)
      }
    }
  }

  const onRestrictionToggle = (id: keyof RewardsTypes.RestrictionKeyType, value: boolean) => {
    let updatedRestrictionData =
      getRewardsRequestObj.rewardRuleData && getRewardsRequestObj.rewardRuleData[id as keyof RewardsTypes.RestrictionKeyType]
    if (updatedRestrictionData) {
      updatedRestrictionData = {
        ...updatedRestrictionData,
        isRestrictionAvaliable: value,
        state: value ? [] : null,
      }
      if (getRewardsRequestObj.rewardRuleData) {
        const data: RewardsTypes.RewardResponseBL = {
          ...getRewardsRequestObj,
          rewardRuleData: {
            ...getRewardsRequestObj.rewardRuleData,
            [id]: updatedRestrictionData,
          },
        }
        updateRewardsRequestObj(data)
        setIndividualReward(data)
      }
    }
  }

  const onStateChange = (id: string, values: string[]) => {
    let updatedRestrictionData =
      getRewardsRequestObj.rewardRuleData && getRewardsRequestObj.rewardRuleData[id as keyof RewardsTypes.RestrictionKeyType]
    if (updatedRestrictionData) {
      updatedRestrictionData = {
        ...updatedRestrictionData,
        state: [...values],
      }
      if (getRewardsRequestObj.rewardRuleData) {
        const data: RewardsTypes.RewardResponseBL = {
          ...getRewardsRequestObj,
          rewardRuleData: {
            ...getRewardsRequestObj.rewardRuleData,
            [id]: updatedRestrictionData,
          },
        }
        updateRewardsRequestObj(data)
        setIndividualReward(data)
      }
    }
  }

  const updateRewardTriggerToggle = (id: string, value: boolean) => {
    let updatedTierType = getRewardsRequestObj.rewardRuleData && getRewardsRequestObj.rewardRuleData[id as keyof RewardsTypes.TriggerRulesData]
    if (updatedTierType) {
      updatedTierType = {
        ...updatedTierType,
        triggerData: {
          ...updatedTierType.triggerData,
          isAllTiers: true,
          tierData: null,
        },
      }
      if (getRewardsRequestObj.rewardRuleData) {
        const data: RewardsTypes.RewardResponseBL = {
          ...getRewardsRequestObj,
          isRedemption: id === 'pointRedemption' ? value : getRewardsRequestObj.isRedemption,
          isTierBenefit: id === 'tierBenefit' ? value : getRewardsRequestObj.isTierBenefit,
          isDirectReward: id === 'surpriseDelight' ? value : getRewardsRequestObj.isDirectReward,
          rewardRuleData: {
            ...getRewardsRequestObj.rewardRuleData,
            [id]: updatedTierType,
          },
        }
        updateRewardsRequestObj(data)
        setIndividualReward(data)
      }
    }
  }

  const renderSaveButton = () => {
    return (
      <Button
        loading={loading}
        onClick={activeStep === 0 ? showPreview : getRewardsRequestObj.voucherStatus === 'APPROVED' ? approvePromotion : handleNext}
        type="primary"
        disabled={false}
        $endIcon={<IconArrowRightLong color={Colors.WHITE} size={16} />}
      >
        {activeStep === 1 ? (getRewardsRequestObj.voucherStatus === 'APPROVED' ? 'Save' : 'Submit for approval') : 'Preview'}
      </Button>
    )
  }

  const renderRewardsFooter = () => {
    return (
      !isViewOnly && (
        <NewRewardFooter>
          {allowBack && showRewardEdit ? (
            <Button type="primary" onClick={handleEditReward}>
              Edit
            </Button>
          ) : (
            <></>
          )}

          {(showRewardEdit || showRewardCreate) && renderSaveButton()}
        </NewRewardFooter>
      )
    )
  }
  const renderApprovalModal = () => {
    if (sentForApproval) {
      const managerObj = getApprovalManagerDetails(approvalManager)
      const managerName = managerObj?.name || ''
      return (
        <>
          <ModalTitle>
            <IconSuccess />
            &nbsp;
            <Typography.Title level={1} uppercase>
              Submitted for Approval
            </Typography.Title>
          </ModalTitle>
          <StyledModalContent align="center">
            <MarginBottomWrapper>
              <Seperator />
            </MarginBottomWrapper>
            <MarginBottomWrapper width="80%">
              <Typography.Paragraph size="medium">
                New reward named, {getRewardsRequestObj.voucherTypeName} has been submitted for {managerName} approval.
              </Typography.Paragraph>
            </MarginBottomWrapper>
            <MarginBottomWrapper>
              <Button type="primary" onClick={handleSuccessApproval}>
                Close
              </Button>
            </MarginBottomWrapper>
          </StyledModalContent>
        </>
      )
    }
    if (confirmRejection) {
      return (
        <>
          <ModalTitle>
            <Typography.Title level={1} uppercase>
              Manager Rejection Reason
            </Typography.Title>
          </ModalTitle>
          <StyledModalContent align="flex-start">
            <MarginBottomWrapper width="100%">
              <Typography.Paragraph size="medium">
                <Row>
                  <Col span={6}>Reward Id</Col>
                  <Col span={18}>{getRewardsRequestObj.voucherTypeCode}</Col>
                </Row>
                <Row>
                  <Col span={6}>Reward Name</Col>
                  <Col span={18}>{getRewardsRequestObj.voucherTypeName}</Col>
                </Row>
              </Typography.Paragraph>
            </MarginBottomWrapper>
            <MarginBottomWrapper width="100%">
              <TextArea id="comments" label="Reject Description" maxLength={100} value={comments} handleChange={handleChange} />
            </MarginBottomWrapper>
            <MarginBottomWrapper width="100%">
              <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button type="link" onClick={handleCancelRejection}>
                  Cancel
                </Button>
                <Button type="primary" loading={isSaving} onClick={handleSubmitForRejection}>
                  Send
                </Button>
              </div>
            </MarginBottomWrapper>
          </StyledModalContent>
        </>
      )
    }
    if (rejected) {
      return (
        <>
          <ModalTitle>
            <IconSuccess />
            &nbsp;
            <Typography.Title level={1} uppercase>
              Rejected
            </Typography.Title>
          </ModalTitle>
          <StyledModalContent align="center">
            <MarginBottomWrapper>
              <Seperator />
            </MarginBottomWrapper>
            <MarginBottomWrapper width="80%">
              <Typography.Paragraph size="medium">Reward is Rejected.</Typography.Paragraph>
            </MarginBottomWrapper>
            <MarginBottomWrapper>
              <Button type="primary" onClick={handleRejectionSuccess}>
                Close
              </Button>
            </MarginBottomWrapper>
          </StyledModalContent>
        </>
      )
    }
    if (approved) {
      return (
        <>
          <ModalTitle>
            <IconSuccess />
            &nbsp;
            <Typography.Title level={1} uppercase>
              Approved
            </Typography.Title>
          </ModalTitle>
          <StyledModalContent align="center">
            <MarginBottomWrapper>
              <Seperator />
            </MarginBottomWrapper>
            <MarginBottomWrapper width="80%">
              <Typography.Paragraph size="medium">Reward is Approved.</Typography.Paragraph>
            </MarginBottomWrapper>
            <MarginBottomWrapper>
              <Button type="primary" onClick={handleApproved}>
                Close
              </Button>
            </MarginBottomWrapper>
          </StyledModalContent>
        </>
      )
    }
    const hasManagersList = managerOptions && managerOptions.length > 0
    return (
      <>
        <ModalTitle>
          <Typography.Title level={1} uppercase>
            Submit for Manager Approval
          </Typography.Title>
        </ModalTitle>
        <StyledModalContent align="flex-start">
          <MarginBottomWrapper width="100%">
            <Typography.Paragraph size="medium">
              <Row>
                <Col span={6}>Reward Id</Col>
                <Col span={18}>{getRewardsRequestObj.voucherTypeCode}</Col>
              </Row>
              <Row>
                <Col span={6}>Reward Name</Col>
                <Col span={18}>{getRewardsRequestObj.voucherTypeName}</Col>
              </Row>
            </Typography.Paragraph>
          </MarginBottomWrapper>
          {!hasManagersList && <Typography.Paragraph>No Manager assigned.</Typography.Paragraph>}
          {hasManagersList && (
            <MarginBottomWrapper width="90%">
              <Form.Select id="manager" label="Select Manager" options={managerOptions} value={approvalManager} onChange={handleChange} />
            </MarginBottomWrapper>
          )}
          {hasManagersList && (
            <MarginBottomWrapper width="100%">
              <TextArea id="comments" label="Comments (Optional)" maxLength={100} value={comments} handleChange={handleChange} />
            </MarginBottomWrapper>
          )}
          <MarginBottomWrapper width="100%">
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              <Button type="link" onClick={handleCancelApproval}>
                Cancel
              </Button>
              <Button type="primary" disabled={!hasManagersList} loading={isSaving} onClick={handleSubmitForApproval}>
                Submit
              </Button>
            </div>
          </MarginBottomWrapper>
        </StyledModalContent>
      </>
    )
  }

  const renderRewardsBody = () => {
    if (loadingRewards) {
      return <LoadingPanel />
    }
    if (rewardId !== 'new' && !individualReward) {
      setTimeout(() => {
        setShowFirst(false)
      }, 2000)
      return <div>{showFirst ? <LoadingPanel /> : <div>No Reward found with {actualRewardId}</div>}</div>
    }
    if (activeStep === 0) {
      return (
        <NewRewardForm
          ref={rewardFormRef}
          partnerCodeOptions={partnerCodeOptions}
          tiersData={tiersData}
          handleTierTypeChange={handleTierTypeChange}
          onTierSelection={onTierSelection}
          onRestrictionToggle={onRestrictionToggle}
          onStateChange={onStateChange}
          updateRewardTriggerToggle={updateRewardTriggerToggle}
          getMemberCount={getMemberCount}
          rewardDataOptions={rewardDataOptions}
          tagsDataOptions={tagsDataOptions}
          categoryDataOptions={categoryDataOptions}
        />
      )
    }
    return <ReviewRewards tagDataOptions={tagsDataOptions} categoryDataOptions={categoryDataOptions} />
  }

  return (
    <div>
      <BreadcrumbsHeaderWrapper>
        <Breadcrumbs backLinkLabel="Rewards" backLinkPath={`/rewards`} activePageLabel="Add New Rewards" />
        <Button type="link" onClick={handleCancelNewReward}>
          {rewardId === 'new' ? 'Cancel New Reward' : 'Cancel'}
        </Button>
      </BreadcrumbsHeaderWrapper>
      <RewardsHeader activeStep={activeStep} />
      {renderRewardsFooter()}
      {activeStep === 1 &&
        getRewardsRequestObj.voucherStatus != null &&
        getRewardsRequestObj.voucherStatus.toUpperCase() === Appconfig.rewards.statusCodes.pending.toUpperCase() &&
        isManager && (
          <MarginBottomWrapper>
            <StepsBodyContainer>
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography.Text>Manager Review</Typography.Text>
                <div>
                  <Button type="link" onClick={confirmRejectApproval}>
                    Reject
                  </Button>
                  <Button type="primary" onClick={approvePromotion} disabled={isSaving}>
                    Approve
                  </Button>
                </div>
              </div>
            </StepsBodyContainer>
          </MarginBottomWrapper>
        )}
      <RewardsFormBodyContainer>{renderRewardsBody()}</RewardsFormBodyContainer>
      {renderRewardsFooter()}
      {openApproval && (
        <StyledModal width={getModalWidth()} open={openApproval} footer={null} onCancel={handleClosePopup}>
          {renderApprovalModal()}
        </StyledModal>
      )}
      {open && (
        <Modal open={open} onOk={handleOk} onCancel={handleCancelModal} footer={null}>
          <StyledModalContent align="center">
            <ModalTitle>
              <Typography.Title level={1}>Are you sure?</Typography.Title>
            </ModalTitle>
            <MarginBottomWrapper>
              <Typography.Paragraph size="medium">
                Are you sure you want to cancel adding {getRewardsRequestObj.voucherTypeName}?
              </Typography.Paragraph>
            </MarginBottomWrapper>
            <MarginBottomWrapper>
              <Button type="primary" key="submit" onClick={handleOk}>
                Continue with cancel
              </Button>
            </MarginBottomWrapper>
            <MarginBottomWrapper>
              <Button type="link" key="back" onClick={handleCancelModal}>
                Back
              </Button>
            </MarginBottomWrapper>
          </StyledModalContent>
        </Modal>
      )}
    </div>
  )
}

export default AddNewRewards
