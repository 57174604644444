import {Col, Modal, Row} from 'antd'
import Form from 'lib/components/Form'
import Button from 'lib/components/Button'
import Typography from 'lib/components/Typography'
import {MarginBottomWrapper, ModalTitle, Seperator, StyledModalContent} from 'app/common/components/Styled/common.styled'
import Appconfig from 'app/common/helpers/AppConfig'
import dayjs from 'dayjs'
import DataTable from 'lib/components/DataTable'
import {NoRecordFound} from 'lib/components/DataTable/DataTable.styled'
import {cloneDeep, isEmpty} from 'lodash'
import {useRef, useState} from 'react'
import {getRewardsRequestObj} from '../AddNewRewrds/utils'
import RewardService from 'app/services/RewardService'
import {utcDate, utcWithTimeZone} from 'utils/convertDateToString'
import IconArrowRightLong from 'app/common/icons/IconArrowRightLong'
import IconSuccess from 'app/common/icons/IconSuccess'
import Colors from 'styles/Colors'
import {handleDisableFromDate, handleDisableToDate} from 'utils/DatepickerUtils'
import {StyledAddQtyWrapper, InputNumberWrapper, InputNumberUnit, ModalActionStyled, RewardDatePickerWrapper} from '../Reward.Styled'
import {FormTypes} from 'lib/components/Form/Form'
import IconDelete from 'app/common/icons/IconDelete'
import IconEdit from 'app/common/icons/IconEdit'
interface SearchResultProps {
  handleSearch(searchFields?: MemebershipTypes.MembershipDetail): void
  isFetching: boolean
  priceHistoryResponse: ResponseTypes.PagedResponse<RewardsTypes.RewardPriceHistoryState[]>
  //rowClick(row: RewardsTypes.RewardPriceHistoryState): void
  handlePaginationChange: (page: number, pageSize: number) => void
  pageSizeOptions?: number[]
  paginationObj: PaginationType.PaginationDetails
  showSearch?: boolean
  showTable?: boolean
  showMemberDetail?: boolean
  getPriceHistoryList(params?: RewardsTypes.RewardPriceSearchParam): void
}

const searchColumns: DataTableTypes.Column[] = [
  {
    title: 'Start Date',
    id: 'ValidFrom',
    textAlign: 'left',
    width: '30%',
  },
  {
    title: 'End Date',
    id: 'ValidUntil',
    textAlign: 'left',
    width: '30%',
  },

  {
    title: 'Points Cost',
    id: 'point',
    textAlign: 'left',
    width: '25%',
  },
  // {
  //   title: 'Currency Cost',
  //   id: 'currency',
  //   textAlign: 'left',
  //   width: '23%',
  //   wrapText: true,
  // },
  // {
  //   title: 'Cost Per Point',
  //   id: 'value',
  //   textAlign: 'left',
  //   width: '14%',
  // },
  {
    title: '',
    id: 'actions',
    textAlign: 'left',
    width: '15%',
  },
]
const dataTableColumns: DataTableTypes.Column[] = [
  {
    title: 'Start Date',
    id: 'validFrom',
    textAlign: 'left',
    width: '30%',
  },
  {
    title: 'End Date',
    id: 'validUntil',
    textAlign: 'left',
    width: '30%',
  },

  {
    title: 'Points Cost',
    id: 'point',
    textAlign: 'left',
    width: '25%',
  },
  // {
  //   title: 'Currency Cost',
  //   id: 'currency',
  //   textAlign: 'left',
  //   width: '23%',
  //   wrapText: true,
  // },
  // {
  //   title: 'Cost Per Point',
  //   id: 'value',
  //   textAlign: 'left',
  //   width: '14%',
  // },
  {
    title: '',
    id: 'actions',
    textAlign: 'left',
    width: '15%',
  },
]

const SearchPriceResult: React.FC<SearchResultProps> = ({
  handleSearch,
  priceHistoryResponse,
  isFetching,
  pageSizeOptions = Appconfig.DEFAULT_PAGINATION.PAGINATION_OPTIONS,
  handlePaginationChange,
  paginationObj,
  showSearch,
  showTable,
  getPriceHistoryList,
}) => {
  let voucherData = getRewardsRequestObj
  const formRef = useRef<HTMLFormElement>(null)
  const initialState: RewardsTypes.RewardPriceHistoryState = {
    voucherTypeName: '',
    voucherTypeCode: '',
    partnerCode: '',
    point: 0,
    validFrom: utcDate(utcWithTimeZone(new Date().toISOString()), Appconfig.DATE_TIME_FORMAT),
    validUntil: null,
  }
  const [state, setState] = useState<RewardsTypes.RewardPriceHistoryState>(initialState)
  const [updatingPriceHistory, setUpdatingPriceHistory] = useState<boolean>(false)
  const [updatingDeletePriceHistory, setUpdatingDeletePriceHistory] = useState<boolean>(false)
  const [priceSaleUpdateSuccess, setPriceSaleUpdateSuccess] = useState<boolean>(false)
  const [priceDeleteSaleUpdateSuccess, setPriceDeleteSaleUpdateSuccess] = useState<boolean>(false)
  const [openPriceSaleModal, setOpenPriceSaleModal] = useState<boolean>(false)
  const [openDeletePriceSaleModal, setOpenDeletePriceSaleModal] = useState<boolean>(false)
  const [isEditable, setIsEditable] = useState<boolean>(false)
  const [priceHistoryId, setPriceHistoryId] = useState<number>(0)
  const handleFormSearch = (searchData?: RewardsTypes.RewardPriceSearchParam) => {
    const hasSearchData = isEmpty(searchData)

    if (!hasSearchData) {
      handleSearch({...searchData, PageNumber: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_NUMBER})
    }
  }

  const handleOnChange = (page: number, pageSize: number) => {
    const formData = new FormData(formRef.current ?? undefined)
    const fieldValues = Object.fromEntries(formData.entries())
    const filledObj: {[k: string]: FormDataEntryValue} = {}
    Object.keys(fieldValues).map((key) => {
      if (fieldValues[key]) {
        filledObj[key] = fieldValues[key]
      }
      return null
    })

    if (pageSize !== Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE || page !== Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_NUMBER) {
    } else {
    }

    handleSearch({
      ...(filledObj || {}),
      PageCount: pageSize,
      PageNumber: page,
    })
    handlePaginationChange(page, pageSize)
  }

  const renderRowActions = (index: number, item: RewardsTypes.RewardPriceHistoryState) => {
    return (
      <>
        <Button type="text" icon={<IconEdit size={16} />} onClick={() => handleEdit(index, item)}></Button>
        <Button type="text" icon={<IconDelete size={16} />} onClick={() => handleDelete(index, item)}></Button>
      </>
    )
  }

  const showAddSalePriceModal = (isEdit: boolean) => {
    setIsEditable(isEdit)
    setOpenPriceSaleModal(true)
  }
  const showDeletePriceModal = () => {
    setOpenDeletePriceSaleModal(true)
  }

  const handleCancelDeleteModal = (): void => {
    setOpenDeletePriceSaleModal(false)
    getPriceHistoryList()
    setPriceDeleteSaleUpdateSuccess(false)
    setPriceHistoryId(0)
    setState(initialState)
  }

  const handleCancelModal = (): void => {
    setOpenPriceSaleModal(false)
    getPriceHistoryList()
    setPriceSaleUpdateSuccess(false)
    setState(initialState)
  }
  const handleEdit = (index: number, item: RewardsTypes.RewardPriceHistoryState) => {
    showAddSalePriceModal(true)
    setState(item)
  }
  const handleDelete = (index: number, item: RewardsTypes.RewardPriceHistoryState) => {
    showDeletePriceModal()
    setPriceHistoryId(item.voucherPriceHistoryId || 0)
    setState(item)
  }

  const handleDeletePrice = (): void => {
    const params: RewardsTypes.RewardPriceHistoryDeleteParam = {
      priceHistoryId: priceHistoryId,
    }
    setUpdatingDeletePriceHistory(true)
    RewardService.deletePriceHIstory(params)
      .then((res) => {
        setPriceDeleteSaleUpdateSuccess(true)
      })
      .finally(() => {
        setUpdatingDeletePriceHistory(false)
      })
  }

  const handlePriceUpdate = (): void => {
    const payload: RewardsTypes.RewardPriceHistoryState = {
      voucherTypeCode: voucherData?.voucherTypeCode || '',
      voucherTypeName: voucherData?.voucherTypeName || '',
      partnerCode: voucherData?.partnerCode || '',
      point: state.point,
      validFrom: state?.validFrom || utcWithTimeZone(new Date().toString()),
      validUntil: state?.validUntil || null,
    }
    const params: RewardsTypes.RewardPriceHistoryParam = {
      voucherPriceHistoryId: isEditable ? state?.voucherPriceHistoryId : 0,
      partnerCode: isEditable ? voucherData?.partnerCode : undefined,
    }
    setUpdatingPriceHistory(true)
    RewardService.saveRewardPriceHistory(payload, params)
      .then((res) => {
        setPriceSaleUpdateSuccess(true)
      })
      .finally(() => {
        setUpdatingPriceHistory(false)
      })
  }
  const handleDateChange = (date: string | null, key: keyof RewardsTypes.RewardsWhenData) => {
    const prevState = cloneDeep(state)
    let dateTimeString = date ? dayjs(date).format() : null
    // if (key === 'validUntil') {
    //   dateTimeString = date ? dayjs(date).endOf('day').format() : null
    //   getRewardsRequestObj[key] = dayjs(dateTimeString).format(Appconfig.DATE_TIME_SERVER)
    // } else {
    //   getRewardsRequestObj[key] = date ? dayjs(dateTimeString).endOf('day').format(Appconfig.DATE_TIME_SERVER) : null
    // }
    dateTimeString = dateTimeString ? dayjs(dateTimeString).format(Appconfig.DATE_TIME_SERVER) : null
    prevState[key] = dateTimeString
    getRewardsRequestObj[key] = dateTimeString
    setState(prevState)
  }
  const handleStateChange = (value: FormTypes.ValueType, key: string) => {
    const prevState = cloneDeep(state)
    setState({
      ...prevState,
      [key]: value,
    })
  }
  const getDeleteModalPriceHistoryContent = (): JSX.Element => {
    if (priceDeleteSaleUpdateSuccess) {
      return (
        <StyledModalContent align="center">
          <ModalTitle>
            <IconSuccess />
            &nbsp;
            <Typography.Title level={2} uppercase>
              Sale has been deleted
            </Typography.Title>
          </ModalTitle>
          <MarginBottomWrapper>
            <Seperator />
          </MarginBottomWrapper>
          <MarginBottomWrapper>
            <Typography.Paragraph size="medium">
              Sale related to rewards {voucherData?.voucherTypeName} {utcDate(utcWithTimeZone(state.validFrom as string), Appconfig.DATE_TIME_FORMAT)}
              {state.validUntil == null ? '' : ` to ${utcDate(utcWithTimeZone(state.validUntil), Appconfig.DATE_TIME_FORMAT)}`} has been deleted.
            </Typography.Paragraph>
          </MarginBottomWrapper>
          <MarginBottomWrapper>
            <Button type="primary" key="back" onClick={() => handleCancelDeleteModal()}>
              Close
            </Button>
          </MarginBottomWrapper>
        </StyledModalContent>
      )
    }
    return (
      <>
        <StyledModalContent align="center">
          <ModalTitle>
            <Typography.Title level={2} uppercase>
              Are you sure you want to delete this sale?
            </Typography.Title>
          </ModalTitle>
          <MarginBottomWrapper>
            <Typography.Paragraph size="medium">This action is permanent and cannot be undone.</Typography.Paragraph>
          </MarginBottomWrapper>
          <MarginBottomWrapper>
            <Seperator />
          </MarginBottomWrapper>
          <MarginBottomWrapper>
            <Typography.Paragraph size="medium">
              Sale details related to rewards {voucherData?.voucherTypeName} from{' '}
              {utcDate(utcWithTimeZone(state.validFrom as string), Appconfig.DATE_TIME_FORMAT)}
              {state.validUntil == null ? '' : ` to ${utcDate(utcWithTimeZone(state.validUntil), Appconfig.DATE_TIME_FORMAT)}`} would be deleted and
              this action cannot be undone.
            </Typography.Paragraph>
          </MarginBottomWrapper>
          <ModalActionStyled>
            <Button type="text" onClick={() => handleCancelDeleteModal()}>
              Cancel
            </Button>
            <Button
              type="primary"
              loading={updatingDeletePriceHistory}
              onClick={handleDeletePrice}
              $endIcon={<IconArrowRightLong color={Colors.WHITE} />}
            >
              Delete Sale
            </Button>
          </ModalActionStyled>
        </StyledModalContent>
      </>
    )
  }
  const getModalPriceHistoryContent = (): JSX.Element => {
    if (priceSaleUpdateSuccess) {
      return (
        <StyledModalContent align="center">
          <ModalTitle>
            <IconSuccess />
            &nbsp;
            <Typography.Title level={2} uppercase>
              Sale price has been {isEditable ? 'edited' : 'added'}!
            </Typography.Title>
          </ModalTitle>
          <MarginBottomWrapper>
            <Seperator />
          </MarginBottomWrapper>
          <MarginBottomWrapper>
            <Typography.Paragraph size="medium">
              A sale price for limited duration has been {isEditable ? 'edited' : 'added'} to the reward {voucherData?.voucherTypeName}.
            </Typography.Paragraph>
          </MarginBottomWrapper>
          <MarginBottomWrapper>
            <Button type="primary" key="back" onClick={() => handleCancelModal()}>
              Close
            </Button>
          </MarginBottomWrapper>
        </StyledModalContent>
      )
    }
    return (
      <StyledModalContent align="center">
        <ModalTitle>
          <Typography.Title level={2} uppercase>
            {isEditable ? 'Edit' : 'Add'} Sale Price
          </Typography.Title>
        </ModalTitle>
        <MarginBottomWrapper>
          <Seperator />
        </MarginBottomWrapper>
        <StyledAddQtyWrapper>
          <Row gutter={32}>
            <Col xl={12}>
              <RewardDatePickerWrapper>
                <Form.DatePicker
                  id="validFrom"
                  disabledDate={(current: dayjs.Dayjs) => handleDisableFromDate(current, state.validFrom, state.validUntil)}
                  value={state.validFrom}
                  onChange={handleDateChange}
                  label="Start Date"
                  disabled={isEditable && (state.validFrom != null ? state?.validFrom < utcWithTimeZone(new Date().toISOString()) : true)}
                  showTime
                />
                <span style={{marginLeft: '8px', marginTop: '16px'}}>UTC</span>
              </RewardDatePickerWrapper>
            </Col>
            <Col xl={12}>
              <RewardDatePickerWrapper>
                <Form.DatePicker
                  id="validUntil"
                  disabledDate={(current: dayjs.Dayjs) => handleDisableToDate(current, state.validFrom, state.validUntil)}
                  value={state.validUntil}
                  onChange={handleDateChange}
                  label="End Date"
                  disabled={state.validUntil != null ? state?.validUntil < utcWithTimeZone(new Date().toISOString()) : false}
                  showTime
                />
                <span style={{marginLeft: '8px', marginTop: '16px'}}>UTC</span>
              </RewardDatePickerWrapper>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <InputNumberWrapper>
                <Form.InputNumber
                  id="point"
                  name="point"
                  label="Points Cost"
                  min={0}
                  max={1000000}
                  width={102}
                  value={state.point}
                  onChange={handleStateChange}
                  precision={0}
                  disabled={isEditable && (state.validFrom != null ? state?.validFrom < utcWithTimeZone(new Date().toISOString()) : true)}
                />
                <InputNumberUnit>Points</InputNumberUnit>
              </InputNumberWrapper>
            </Col>
          </Row>
        </StyledAddQtyWrapper>
        <ModalActionStyled>
          <Button type="text" onClick={() => handleCancelModal()}>
            Cancel
          </Button>
          <Button
            disabled={state.validUntil != null ? state?.validUntil < utcWithTimeZone(new Date().toISOString()) : false}
            type="primary"
            loading={updatingPriceHistory}
            onClick={handlePriceUpdate}
            $endIcon={<IconArrowRightLong color={Colors.WHITE} />}
          >
            Save Changes
          </Button>
        </ModalActionStyled>
      </StyledModalContent>
    )
  }

  const generateRowData = (): DataTableTypes.Row[] => {
    if (priceHistoryResponse && priceHistoryResponse.data) {
      const dataTableRows: DataTableTypes.Row[] = []
      const {data} = priceHistoryResponse
      data.map((item, index) => {
        const obj: DataTableTypes.Row = {
          validFrom: item.validFrom ? dayjs(item.validFrom).format(Appconfig.DATE_FORMAT_SLASH) : '-',
          validUntil: item.validUntil ? dayjs(item.validUntil).format(Appconfig.DATE_FORMAT_SLASH) : '-',
          point: item.point,
          actions: renderRowActions(index, item),
        }
        dataTableRows.push(obj)
        return null
      })

      return dataTableRows
    }
    return []
  }

  // const handleResetSearch = () => {
  //   if (!isFormReset) {
  //     setIsFormReset(!isFormReset)
  //     formRef.current?.reset()

  //     const newPaginationObj = {
  //       PageCount: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
  //       PageNumber: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_NUMBER,
  //     }
  //     handlePaginationChange(Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_NUMBER, Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE)
  //     handleSearch(newPaginationObj)
  //   } else {
  //     formRef.current?.reset()
  //   }
  //   console.log(formRef.current)
  // }

  // const handleRowClick = (index: number) => {
  //   if (priceHistoryResponse) {
  //     rowClick(priceHistoryResponse.data[index])
  //   }
  // }

  const renderResetSearch = () => {
    return (
      <Button color="primary" onClick={() => showAddSalePriceModal(false)} type="text" disabled={voucherData.voucherStatus !== 'APPROVED'|| !voucherData.isRedemption }>
        New Sale
      </Button>
    )
  }

  const renderNoRecordFound = () => {
    return <NoRecordFound>No search results were found for your query. Maybe give it another try ?</NoRecordFound>
  }

  const {totalPages, pageIndex, pageSize, count} = priceHistoryResponse.pageInfo
  return (
    <DataTable>
      {showSearch && showTable && (
        <DataTable.Search ref={formRef} isFetching={isFetching} handleSearch={handleFormSearch} columns={searchColumns} isDark={true} />
      )}
      <DataTable.TotalResult resetSearch={renderResetSearch()} noOfResults={count} showSearch={showSearch && showTable} showTable={showTable} />
      {showTable && (
        <div>
          <DataTable.Header columns={dataTableColumns} />
          <DataTable.Rows
            noRecordFoundComponent={renderNoRecordFound()}
            loading={isFetching}
            columns={dataTableColumns}
            rows={generateRowData()}
            //rowActions={{rowClick: handleRowClick}}
          />
          {openPriceSaleModal && (
            <Modal open={openPriceSaleModal} onCancel={() => handleCancelModal()} footer={null}>
              {getModalPriceHistoryContent()}
            </Modal>
          )}
          {openDeletePriceSaleModal && (
            <Modal open={openDeletePriceSaleModal} onCancel={() => handleCancelDeleteModal()} footer={null}>
              {getDeleteModalPriceHistoryContent()}
            </Modal>
          )}
          <DataTable.Pagination
            onChange={handleOnChange}
            current={pageIndex}
            pageSizeOptions={pageSizeOptions}
            total={totalPages * pageSize}
            defaultPageSize={Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_NUMBER}
            pageSize={paginationObj.PageCount}
            showSizeChanger
          />
        </div>
      )}
    </DataTable>
  )
}

export default SearchPriceResult
